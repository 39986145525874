<template>
  <main-layout>
    <div class="d-flex align-items-center">
      <base-button @click="$router.back()" :back="true">
        <horizontal-arrow />
      </base-button>
      <h1 class="m-0 ms-3">{{ transactionInformation.company.name }}</h1>
    </div>
    <div class="transactionSinglePage">
      <div class="row line-bottom">
        <div class="col-lg-3">
          <div class="transactionSinglePage__title">{{ $t("Company") }}</div>
          <div
            class="transactionSinglePage__value transactionSinglePage__value-think"
          >
            <div>{{ transactionInformation.company.name }}</div>
            <div>{{ transactionInformation.company.code }}</div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="transactionSinglePage__title">
            {{ $t("transactionDate") }}
          </div>
          <div
            class="transactionSinglePage__value transactionSinglePage__value-think"
          >
            <div>
              <data-formatted
                v-if="transactionInformation.date_paid"
                date-format="HH:mm"
                :data="transactionInformation.date_paid"
              />
            </div>
            <div>
              <data-day
                v-if="transactionInformation.date_paid"
                :data="transactionInformation.date_paid"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="transactionSinglePage__title">{{ $t("totalPaid") }}</div>
          <div class="transactionSinglePage__value">
            €{{ transactionInformation.total }}
          </div>
        </div>
        <div class="col-lg-3">
          <div class="transactionSinglePage__title">{{ $t("Status") }}</div>
          <div class="transactionSinglePage__value">
            {{ transactionInformation.status.name }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 mt-4">
          <div class="transactionSinglePage__title">
            {{ $t("contactInfo") }}
          </div>
          <div
            class="transactionSinglePage__value transactionSinglePage__value-think"
          >
            <div>
              {{
                transactionInformation.company.contact.first_name +
                " " +
                transactionInformation.company.contact.last_name
              }}
            </div>
            <div>
              {{ transactionInformation.company.user.email }}
            </div>
          </div>
        </div>
        <div class="col-lg-3 mt-4">
          <div class="transactionSinglePage__title">
            {{ $t("billingInfo") }}
          </div>
          <div
            class="transactionSinglePage__value transactionSinglePage__value-think"
          >
            <div>
              {{
                transactionInformation.company.billing.first_name +
                " " +
                transactionInformation.company.billing.last_name
              }}
            </div>
            <div>
              {{ transactionInformation.company.billing.email }}
            </div>
          </div>
        </div>
        <div class="col-lg-3 mt-4">
          <div class="transactionSinglePage__title">
            {{ $t("subscriptionCoast") }}
          </div>
          <div class="transactionSinglePage__value">
            €{{ transactionInformation.subscription_price }}
          </div>
        </div>
        <div class="col-lg-3 mt-4">
          <div class="transactionSinglePage__title">
            {{ $t("coverageAmount") }}
          </div>
          <div class="transactionSinglePage__value">
            €{{ transactionInformation.appointments_coverage }}
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import BaseButton from "@/components/UI/buttons/BaseButton";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import DataDay from "@/components/components-helpers/DataDay";
import DataFormatted from "@/components/components-helpers/DataFormatted";

export default {
  name: "TransactionCompanySinglePage",
  components: {
    DataDay,
    HorizontalArrow,
    DataFormatted,
    BaseButton,
    MainLayout,
  },
  data() {
    return {
      transactionInformation: {
        status: { name: "" },
        company: {
          subscription_price: "",
          appointments_coverage: "",
          user: { email: "" },
          contact: { first_name: "", last_name: "" },
          billing: { first_name: "", last_name: "", email: "" },
          code: "",
          name: "",
        },
        total: "",
        date_paid: new Date(),
      },
    };
  },
  mounted() {
    this.getTransactionInformation();
  },
  methods: {
    getTransactionInformation() {
      this.$http
        .get("/company-transactions/" + this.$route.params.transaction, {})
        .then(({ data }) => {
          this.transactionInformation = data.body;
        });
    },
  },
};
</script>

<style scoped></style>
